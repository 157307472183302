import './App.css';

import { Descope } from '@descope/react-sdk'
import { useSession, useUser } from '@descope/react-sdk'

function Flow() {
  const { isAuthenticated, isSessionLoading } = useSession()
  const { user, isUserLoading } = useUser()

  // flows for escape room
  return <>
    <div className='auth'>
      {!isAuthenticated && 
        (
          <Descope
            flowId="escape-room"
            onSuccess={(e) => console.log(e.detail.user)}
            onError={(e) => console.log('Could not log in!')}
            theme='dark'
          />
        )
      }
      {
        (isSessionLoading || isUserLoading) && <p>Loading...</p>
      }
      {!isUserLoading && isAuthenticated &&
        (
          <Descope
            flowId="escape-room-solution-upload"
            form={{"location": user.customAttributes.location, "description": user.customAttributes.description, "customAttributes.companyId": user.customAttributes.companyId}}
            onError={(e) => console.log('Could not log in!')}
            theme='dark'
          />
        )
      }
    </div>
  </>;
}

function App() {
  const getDate = (() => {
    const today = new Date();
    const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
    const longDate = today.toLocaleDateString("en-US", options);
    return longDate;
  })

  

  return (
    <div className='all'>
      <div class="date">
			  <p id="date"><strong>{getDate()}</strong></p>
		  </div>
      <div class="warning-top">
        <h1>CONFIDENTIAL CODEEPS MESSAGE !! DO NOT SHARE !!</h1>
      </div>
      <div className='auth-contain'>
        <Flow></Flow>
      </div>
      <div class="warning">
        <h1>CONFIDENTIAL CODEEPS MESSAGE !! DO NOT SHARE !!</h1>
      </div>
    </div>
  );
}

export default App;
